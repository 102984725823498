import React, { useEffect, useState } from 'react';
import Img from 'gatsby-image';
import { graphql, useStaticQuery, Link } from 'gatsby';
import Layout from '../layouts/index';
import translationsEn from '../locales/en.json';
import translationsEs from '../locales/es.json';
import Rating from '../components/rating';

const ReviewProducts = () => {
  const [locale, setLocale] = useState('es');
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [productReviews, setProductReviews] = useState({});
  const [token, setToken] = useState(null);
  const [tokenId, setTokenId] = useState(null);
  const [userId, setUserId] = useState(null);
  const [submissionResult, setSubmissionResult] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [isTokenInvalid, setIsTokenInvalid] = useState(false); // Ajustado para manejar un token inválido

  const staticData = useStaticQuery(graphql`
    query {
      products: allDatoCmsProduct {
        edges {
          node {
            id
            seourl
            sku
            name
            price
            locale
            image {
              url
              fluid(maxWidth: 300, imgixParams: { fm: "webp" }) {
                ...GatsbyDatoCmsFluid
              }
            }
          }
        }
      }
      site: datoCmsSite {
        faviconMetaTags {
          tags
        }
        globalSeo {
          siteName
        }
      }
    }
  `);

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const tokenFromUrl = urlParams.get('token');

    if (tokenFromUrl) {
      setToken(tokenFromUrl);

      fetch(`https://api.osdocasal.com:8090/review-token/${tokenFromUrl}`)
        .then(response => {
          const contentType = response.headers.get('content-type');
          if (contentType && contentType.includes('application/json')) {
            return response.json();
          } else {
            throw new Error('Invalid token');
          }
        })
        .then(apiData => {
          const skus = apiData.reviewToken.productSku.split(';');
          const filtered = staticData.products.edges
            .map(edge => edge.node)
            .filter(product => skus.includes(product.sku) && product.locale === locale);
          setFilteredProducts(filtered);
          setTokenId(apiData.reviewToken.tokenId);
          setUserId(apiData.user.id);
        })
        .catch(error => {
          console.error('Error fetching review token:', error);
          if (error.message === 'Invalid token') {
            setIsTokenInvalid(true);
          }
        });
    }
  }, [staticData, locale]);

  useEffect(() => {
    if (typeof window !== "undefined") {
      const storedLocale = localStorage.getItem('locale') || 'es';
      setLocale(storedLocale);
    }

    const handleLanguageChange = (event) => {
      setLocale(event.detail);
    };

    window.addEventListener('languageChanged', handleLanguageChange);
    return () => window.removeEventListener('languageChanged', handleLanguageChange);
  }, []);

  const handleRatingChange = (sku, rating) => {
    setProductReviews(prevState => ({
      ...prevState,
      [sku]: {
        ...prevState[sku],
        rating: rating
      }
    }));
  };

  const handleCommentChange = (sku, comment) => {
    setProductReviews(prevState => ({
      ...prevState,
      [sku]: {
        ...prevState[sku],
        comment: comment
      }
    }));
  };

  const handleSubmitReview = () => {
    if (!token) {
      console.error('Token is not defined');
      return;
    }

    const reviewsArray = Object.entries(productReviews).map(([sku, reviewDetails]) => ({
      productSku: sku,
      rating: reviewDetails.rating,
      comment: reviewDetails.comment,
      tokenId: tokenId,
      userId: userId
    }));

    const reviewsData = {
      token: token,
      reviews: reviewsArray
    };

    const requestOptions = {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(reviewsData),
    };
    
    fetch('https://api.osdocasal.com:8090/submit-review', requestOptions)
      .then(response => response.text())
      .then(result => {
        setSubmissionResult(result);
        setShowModal(true);
      })
      .catch(error => console.error('Error submitting review:', error));
  };

  const translations = locale === 'es' ? translationsEs : translationsEn;

  return (
    <Layout
      site={staticData.site}
      seo={{ ...staticData.site.globalSeo, ...staticData.site.faviconMetaTags }}
      lang={locale}
      title={"Valoración de productos - OS DO CASAL"}
      description={"Valoración de productos"}
      noIndex={true}
    >
      <div className="Review-products">
        {isTokenInvalid ? (
          <>
            <h2>{translations['invalid-token']}</h2>
            <p><Link to={`/`}>{translations['go-to-main-page']}</Link></p>
          </>
        ) : (
          <>
            <h2 className="Review-products__name">{translations['rate-products']}</h2>
            {filteredProducts.map(product => (
              <div className="Review-products__item" key={product.id}>
                <Link to={`/${locale}/product/${product.seourl}`}>
                  <div className="Review-products__item__image">
                    <Img fluid={product.image.fluid} alt={product.name} />
                  </div>
                </Link>
                <div className="Review-products__item__details">
                  <div className="Review-products__item__details__name">{product.name}</div>
                  <div className="Review-products__item__details__price">{product.price}€</div>
                </div>
                <div className="Review-products__item__review">
                  <Rating
                    value={productReviews[product.sku]?.rating || 0}
                    onChange={(value) => handleRatingChange(product.sku, value)}
                  />
                  <textarea
                    placeholder={translations['leave-comment']}
                    value={productReviews[product.sku]?.comment || ''}
                    onChange={(e) => handleCommentChange(product.sku, e.target.value)}
                  />
                </div>
              </div>
            ))}
            <div className="Review-products__submit">
              <button type="button" onClick={handleSubmitReview}>{translations['send']}</button>
            </div>
          </>
        )}
        {showModal && (
          <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: '999',
            backgroundColor: '#fff',
            padding: '20px',
            boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)',
            border: '1px solid black'
          }}>
            <p style={{color: 'black'}}>{submissionResult}</p>
            <button type="button" onClick={() => setShowModal(false)}>{translations['close']}</button>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default ReviewProducts;