import React from 'react';

const Rating = ({ value, onChange }) => {
  return (
    <div className="ratingContainer">
      {[...Array(5)].map((star, index) => {
        const ratingValue = index + 1;

        return (
          <label key={index}>
            <input
              type="radio"
              name="rating"
              value={ratingValue}
              onClick={() => onChange(ratingValue)}
            />
            <span
              className={ratingValue <= value ? "starRated" : ''}
            >
              ★
            </span>
          </label>
        );
      })}
    </div>
  );
};

export default Rating;